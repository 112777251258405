import { ReactElement } from "react";
import * as styles from "./FeatureCard.module.scss";

interface IFeatureCardProps {
    feature: GatsbyTypes.Maybe<GatsbyTypes.strapi_ComponentProductFeature>;
}

export default function FeatureCard({ feature }: IFeatureCardProps): ReactElement {
    return (
        <div key={feature?.base?.id} className={styles.commonFeatures}>
            <h2> {feature?.base?.heading} </h2> <div dangerouslySetInnerHTML={{ __html: feature?.base?.paragraph ?? "" }}></div>
        </div>
    );
}

import { ReactElement, useEffect, useState } from "react";
import { useDependency } from "../../../../../contexts/DependencyContext";
import ShoppingCartService from "../../../../../services/shoppingCart/ShoppingCartService";
import formatCurrency from "../../../../../shared/FormatCurrency";
import { cartIcon } from "../../../../Icons";
import * as styles from "./SalesInfo.module.scss";

interface ISalesInfoProps {
    product: GatsbyTypes.strapi_Product;
    addButtonText: string;
}

export default function SalesInfo({ product, addButtonText }: ISalesInfoProps): ReactElement {
    const [selectedLicense, setSelectedLicense] = useState<GatsbyTypes.strapi_ComponentProductLicenseType>();
    const [sortedTieredPricing, setSortedTieredPricing] = useState<GatsbyTypes.strapi_ComponentProductTieredPricing[]>([]);
    const cartService = useDependency(ShoppingCartService);
    const selectedLicensePrice = (sortedTieredPricing?.length > 0 ? sortedTieredPricing[sortedTieredPricing.length - 1] : undefined)?.price ?? 0;

    useEffect(() => {
        if (product.licensesTypes && product.licensesTypes.length > 0) {
            const firstLicenseType = product.licensesTypes[0] as GatsbyTypes.strapi_ComponentProductLicenseType;
            setSelectedLicense(firstLicenseType);
        }
    }, []);

    useEffect(() => {
        setSortedTieredPricing((selectedLicense?.tieredPricing as GatsbyTypes.strapi_ComponentProductTieredPricing[])?.sort((a, b) => (b.quantity ?? 0) - (a.quantity ?? 0)));
    }, [selectedLicense]);

    function handleSelectionChange(licenseTypeId: string): void {
        const licenseType = product.licensesTypes?.find(x => x?.license_type?.id?.toString() === licenseTypeId);
        setSelectedLicense(licenseType);
    }

    function handlePurchaseClick() {
        cartService.addItemToCart(product, selectedLicense?.id ?? "", sortedTieredPricing, selectedLicensePrice, 1);
    }

    return (
        <>
            {product.licensesTypes && (
                <div>
                    <div className={styles.selectedLicense}>
                        <span>{formatCurrency(selectedLicensePrice)}</span>/<span>{selectedLicense?.license_type?.name}</span>
                    </div>

                    <select onChange={e => handleSelectionChange(e.target.value)} className={styles.selectLicenseType}>
                        {product.licensesTypes?.map(
                            x =>
                                x?.license_type && (
                                    <option key={x.id} value={x.license_type.id}>
                                        {x?.license_type.name}
                                    </option>
                                )
                        )}
                    </select>
                    <div className={styles.addCart}>
                        <button onClick={handlePurchaseClick} disabled={!selectedLicense}>
                            {cartIcon}
                            {addButtonText}
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}

// extracted by mini-css-extract-plugin
export var productHead = "ProductHead-module--productHead--TgTxf";
export var inner = "ProductHead-module--inner--F9RFh";
export var textContainer = "ProductHead-module--textContainer--66sLj";
export var disclaimer = "ProductHead-module--disclaimer--pwJ1x";
export var title = "ProductHead-module--title--flqjE";
export var iconHeader = "ProductHead-module--iconHeader--fSM6b";
export var description = "ProductHead-module--description--Za-AG";
export var category = "ProductHead-module--category--gUXn+";
export var shopBox = "ProductHead-module--shopBox--j9p6e";
export var shopWrapper = "ProductHead-module--shopWrapper--i6KR5";
export var selectionLicense = "ProductHead-module--selectionLicense--BV1aH";
export var seats = "ProductHead-module--seats--iXSfg";
export var priceContainer = "ProductHead-module--priceContainer--w46jU";
export var price = "ProductHead-module--price--9pSG+";
export var unitPrice = "ProductHead-module--unitPrice--OAWvo";
export var license = "ProductHead-module--license--nyxkm";
export var addCart = "ProductHead-module--addCart--e7N3A";
export var imageContainer = "ProductHead-module--imageContainer--w5eEd";
export var image = "ProductHead-module--image--kaJ9N";
import { CSSProperties, ReactElement, useRef } from "react";
import { graphql } from "gatsby";
import { useDependency } from "../../contexts/DependencyContext";
import ProductWidgetService, { WidgetProps } from "../../services/widgets/ProductWidgetService";
import Helmet from "../../components/Helmet";
import Widget from "../../components/Shared/Widget";
import * as styles from "./product.module.scss";

interface IProductProps {
    data: GatsbyTypes.ProductQuery;
}

export default function Product({ data }: IProductProps): ReactElement {
    const widgetService = useDependency(ProductWidgetService);
    const widgetsData = data?.strapi?.productPage?.detail?.map(x => x as WidgetProps);
    const product = data.strapi.product as GatsbyTypes.strapi_Product;
    const supportTypes = data.strapi.supportTypes as GatsbyTypes.strapi_SupportTypes[];

    return (
        <>
            <Helmet title={`Product - ${data.strapi.product?.name}`} description="" />
            <div>
                <div className={styles.container} style={{ "--product-color": product?.color, "--vendor-color": product?.product_vendor?.color } as CSSProperties}>
                    {widgetsData
                        ?.filter(x => !!x)
                        .map(x => (
                            <Widget key={widgetService.getKey(x)}>{widgetService.getWidget(x, product, supportTypes)}</Widget>
                        ))}
                </div>
            </div>
        </>
    );
}

export const query = graphql`
    query Product($id: ID!) {
        strapi {
            product(id: $id) {
                id
                name
                description
                features {
                    ... on strapi_ComponentProductFeature {
                        id
                        special
                        base {
                            id
                            heading
                            paragraph
                        }
                    }
                }
                download {
                    id
                    evaluationDescription
                    installer {
                        version
                        linkFile
                        file {
                            url
                        }
                    }
                    manual {
                        linkFile
                        file {
                            url
                        }
                    }
                    activation {
                        __typename
                        id
                    }
                }
                product_type {
                    id
                    name
                }
                licensesTypes {
                    ... on strapi_ComponentProductLicenseType {
                        id
                        shopifyId
                        license_type {
                            id
                            name
                            description
                            pluralQtyText
                            singularQtyText
                            isSubscription
                        }
                        tieredPricing {
                            quantity
                            price
                        }
                    }
                }
                images {
                    url
                    url_sharp {
                        childImageSharp {
                            gatsbyImageData(quality: 60)
                        }
                    }
                }
                color
                heroImage {
                    url
                    url_sharp {
                        childImageSharp {
                            gatsbyImageData(quality: 60)
                        }
                    }
                }
                overviewImage {
                    url
                    url_sharp {
                        childImageSharp {
                            gatsbyImageData(quality: 60)
                        }
                    }
                }
                product_vendor {
                    name
                    color
                }
            }
            productPage {
                detail {
                    ... on strapi_ComponentProductHead {
                        __typename
                        id
                        btnText
                        disclaimer
                        unitPriceText
                        termsOfService
                        cta {
                            text
                            link
                            external
                        }
                    }
                    ... on strapi_ComponentProductDownload {
                        __typename
                        id
                        heading
                        downloadButtonText
                        manualButtonText
                        textVersionBtn
                    }
                    ... on strapi_ComponentProductGallery {
                        __typename
                        id
                        base {
                            heading
                        }
                    }
                    ... on strapi_ComponentSharedGenericBlock {
                        __typename
                        id
                        widgetType
                        showMoreText
                        showLessText
                    }
                    ... on strapi_ComponentProductOverview {
                        __typename
                        base {
                            heading
                        }
                        id
                    }
                    ... on strapi_ComponentProductSupportBar {
                        __typename
                        id
                        commonTopic {
                            id
                            text
                            link
                            external
                        }
                        title
                        question {
                            base {
                                heading
                                paragraph
                            }
                            cta {
                                text
                                link
                                external
                            }
                        }
                    }
                    ... on strapi_ComponentProductRelatedProducts {
                        __typename
                        id
                        title
                        products {
                            name
                            id
                            color
                        }
                    }
                }
            }
            supportTypes {
                id
                name
                product_types {
                    id
                }
            }
        }
    }
`;

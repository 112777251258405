import { ReactElement } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import * as styles from "./ProductOverview.module.scss";

interface IProductOverview {
    widgetProps: GatsbyTypes.strapi_ComponentProductOverview;
    product: GatsbyTypes.strapi_Product;
}

export default function ProductOverview({ widgetProps, product }: IProductOverview): ReactElement {
    const imageData = product.overviewImage?.url_sharp?.childImageSharp?.gatsbyImageData;

    return (
        <div className={styles.productOverview}>
            <div className={`mainBox ${styles.inner}`}>
                <div>
                    <h1>{widgetProps.base?.heading}</h1>
                </div>
                <div>{imageData && <GatsbyImage image={imageData} alt="Image" />}</div>
            </div>
        </div>
    );
}

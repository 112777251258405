import { ReactElement, useEffect, useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useDependency } from "../../../contexts/DependencyContext";
import ShoppingCartService from "../../../services/shoppingCart/ShoppingCartService";
import formatCurrency from "../../../shared/FormatCurrency";
import NumberInput from "../../Shared/NumberInput";
import { cartIcon, ddcToolboxIcon } from "../../Icons";
import * as styles from "./ProductHead.module.scss";
import CtaLink from "../../Shared/CtaLink";

interface IProductHead {
    widgetProps: GatsbyTypes.strapi_ComponentProductHead;
    product: GatsbyTypes.strapi_Product;
}

export default function ProductHead({ widgetProps, product }: IProductHead): ReactElement {
    const cartService = useDependency(ShoppingCartService);
    const [quantity, setQuantity] = useState(1);
    const [selectedLicense, setSelectedLicense] = useState<GatsbyTypes.strapi_ComponentProductLicenseType>();
    const [unitPrice, setUnitPrice] = useState(0);
    const [sortedTieredPricing, setSortedTieredPricing] = useState<GatsbyTypes.strapi_ComponentProductTieredPricing[]>([]);
    const image = product.heroImage?.url_sharp?.childImageSharp?.gatsbyImageData;
    const isPlural = quantity > 1;
    const totalPrice = quantity * unitPrice;

    /////////////////////////////////////////
    // Selects the default license.
    /////////////////////////////////////////
    useEffect(() => {
        if (product.licensesTypes && product.licensesTypes.length > 0) {
            const firstLicenseType = product.licensesTypes[0];
            setSelectedLicense(firstLicenseType);
            if (firstLicenseType?.tieredPricing && firstLicenseType.tieredPricing.length > 0) setUnitPrice(firstLicenseType.tieredPricing[0]?.price ?? 0);
        }
    }, []);

    /////////////////////////////////////////
    // Sorts the prices by quantity.
    /////////////////////////////////////////
    useEffect(() => {
        setSortedTieredPricing((selectedLicense?.tieredPricing as GatsbyTypes.strapi_ComponentProductTieredPricing[])?.sort((a, b) => (b.quantity ?? 0) - (a.quantity ?? 0)));
    }, [selectedLicense]);

    /////////////////////////////////////////
    // Updates the current price.
    /////////////////////////////////////////
    useEffect(() => {
        for (var i = 0; i < sortedTieredPricing?.length; i++) {
            const pricing = sortedTieredPricing[i];
            if (pricing && pricing.quantity && pricing.quantity <= quantity) {
                setUnitPrice(pricing.price ?? 0);
                break;
            }
        }
    }, [sortedTieredPricing, quantity]);

    function handleLicenseChange(licenseTypeId: string): void {
        const licenseType = product.licensesTypes?.find(x => x?.license_type?.id === licenseTypeId);
        setSelectedLicense(licenseType);
    }

    function handlePurchaseClick() {
        cartService.addItemToCart(product, selectedLicense?.id ?? "", sortedTieredPricing, unitPrice, quantity);
    }

    return (
        <div className={styles.productHead}>
            <div className={`mainBox ${styles.inner}`}>
                <div className={styles.textContainer}>
                    <div className={styles.title}>
                        <div className={styles.iconHeader}>{ddcToolboxIcon}</div>
                        <h1>{product.name}</h1>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: product.description ?? "" }} className={styles.description}></div>

                    <div className={styles.category}>{product.product_vendor?.name}</div>

                    <div className={styles.shopBox}>
                        <div className={styles.shopWrapper}>
                            <div className={styles.selectionLicense}>
                                <select onChange={e => handleLicenseChange(e.target.value)}>
                                    {product.licensesTypes?.map(
                                        x =>
                                            x?.license_type && (
                                                <option key={x.id} value={x.license_type.id}>
                                                    {x?.license_type.name}
                                                </option>
                                            )
                                    )}
                                </select>
                                <div className={styles.seats}>
                                    <NumberInput
                                        label={isPlural ? selectedLicense?.license_type?.pluralQtyText : selectedLicense?.license_type?.singularQtyText}
                                        value={quantity}
                                        onChange={value => setQuantity(value ?? 0)}
                                    />
                                </div>
                            </div>
                            <div className={styles.priceContainer}>
                                <div className={styles.price}>{formatCurrency(totalPrice)}</div>
                                <div className={styles.unitPrice}>
                                    {formatCurrency(unitPrice)} {widgetProps.unitPriceText}
                                </div>
                            </div>
                        </div>

                        <div className={styles.license}>{selectedLicense?.license_type?.description}</div>
                        <div className={styles.addCart}>
                            <button onClick={handlePurchaseClick} disabled={!selectedLicense}>
                                {cartIcon}
                                {widgetProps.btnText}
                            </button>
                        </div>
                    </div>
                    <span className={styles.disclaimer}>
                        {widgetProps.disclaimer}
                        <br />
                        {widgetProps.termsOfService} {widgetProps.cta && <CtaLink cta={widgetProps.cta}></CtaLink>}
                    </span>
                </div>

                <div className={styles.imageContainer}>{image && <GatsbyImage image={image} className={styles.image} alt="image"></GatsbyImage>}</div>
            </div>
        </div>
    );
}

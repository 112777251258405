import { ReactElement } from "react";
import { navigate } from "gatsby-link";
import { arrows, downloadVersionImage } from "../../Icons";
import * as styles from "./ProductDownload.module.scss";

interface IProductDownload {
    widgetProps: GatsbyTypes.strapi_ComponentProductDownload;
    product: GatsbyTypes.strapi_Product;
}

export default function ProductDownload({ widgetProps, product }: IProductDownload): ReactElement {
    const installer = (product.download?.installer as GatsbyTypes.strapi_ComponentDownloadInstaller[])?.sort((a, b) => b.date - a.date)?.find(x => x !== undefined);
    const manual = (product.download?.manual as GatsbyTypes.strapi_ComponentDownloadManual[])?.sort((a, b) => b.date - a.date)?.find(x => x !== undefined);
    const installerLink = installer?.linkFile && installer.linkFile.length > 0 ? installer.linkFile : installer?.file?.url;
    const manualLink = manual?.linkFile && manual.linkFile.length > 0 ? manual.linkFile : manual?.file?.url;

    return (
        <>
            {product.product_type?.name?.toLocaleLowerCase() !== "hardware" && (
                <div className={styles.productDownload}>
                    <div className={`mainBox ${styles.inner}`}>
                        <div className={styles.text}>
                            <h1>{`${widgetProps.heading} ${product.name}`}</h1>
                            {product.download?.evaluationDescription && <span>{product.download.evaluationDescription}</span>}
                            <div className={styles.buttons}>
                                {installerLink && (
                                    <a href={installerLink} target="_blank">
                                        <button>
                                            <div>
                                                <p>{widgetProps.downloadButtonText}</p>
                                                {product.download && product.download.installer && product.download?.installer![0]?.version && (
                                                    <span>
                                                        {widgetProps.textVersionBtn} {product.download.installer[0]?.version}
                                                    </span>
                                                )}
                                            </div>
                                            <div>{arrows.down}</div>
                                        </button>
                                    </a>
                                )}
                                {manualLink && (
                                    <a href={manualLink} target="_blank">
                                        <button>
                                            <div>
                                                <p>{widgetProps.manualButtonText}</p>
                                            </div>
                                            <div>{arrows.down}</div>
                                        </button>
                                    </a>
                                )}
                            </div>
                        </div>

                        <div className={styles.image}>{downloadVersionImage}</div>
                    </div>
                </div>
            )}
        </>
    );
}

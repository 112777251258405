import { ReactElement } from "react";
import shuffleArray from "../../../shared/ShuffleArray";
import Product from "../Products/Product";
import * as styles from "./ProductRelatedProduct.module.scss";

interface IProductRelatedProducts {
    widgetProps: GatsbyTypes.strapi_ComponentProductRelatedProducts;
    product: GatsbyTypes.strapi_Product;
}

export default function ProductRelatedProducts({ product, widgetProps }: IProductRelatedProducts): ReactElement {
    const products = shuffleArray(widgetProps.products?.filter(x => x?.id !== product.id) ?? []);
    const featuredProducts = products.slice(0, Math.min(2, products.length));

    return (
        <div className={styles.relatedProducts}>
            <div className="mainBox">
                <div className={styles.inner}>
                    <h1>{widgetProps.title}</h1>
                    <div className={styles.relatedProduct}>
                        {featuredProducts.map(x => x && <Product key={x.id} product={x} linkText="Learn more" linkTo={`products`} className={styles.singleProduct} />)}
                    </div>
                </div>
            </div>
        </div>
    );
}

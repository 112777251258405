import { CSSProperties, ReactElement, useRef } from "react";
import { arrows } from "../../../Icons";
import SalesInfo from "./SalesInfo";
import { className } from "../../../../shared/ClassName";
import { useDependency } from "../../../../contexts/DependencyContext";
import { NavigationService } from "../../../../services/NavigationService";
import * as styles from "./Product.module.scss";

interface IProductProps {
    product: GatsbyTypes.strapi_Product;
    linkText: string;
    linkTo: string;
    addButtonText?: string;
    className?: string;
}

export default function Product({ product, linkText, linkTo, addButtonText, className: cssName }: IProductProps): ReactElement {
    const navigationService = useDependency(NavigationService);

    async function navigateToProductAsync(): Promise<void> {
        await navigationService.navigateAsync(`/${linkTo}/${product.id}`);
    }

    return (
        <div {...className(styles.box, cssName)} style={{ "--product-color": product.color } as CSSProperties} onClick={navigateToProductAsync}>
            <div className={styles.boxWrapper}>
                <h3>{product.name}</h3>
                <div className={styles.hoverProduct} onClick={e => e.stopPropagation()}>
                    <SalesInfo product={product} addButtonText={addButtonText ?? ""} />
                </div>
                <div className={styles.description} dangerouslySetInnerHTML={{ __html: product.description ?? "" }}></div>
            </div>
            <div className={styles.boxButton}>
                <div className={styles.innerButton}>
                    <p>{linkText ?? ""}</p>
                    <div className={styles.iconContainer}>{arrows.right}</div>
                </div>
            </div>
        </div>
    );
}

import { ReactElement } from "react";
import removeWhiteSpaces from "../../../shared/RemoveWhiteSpaces";
import CtaLink from "../../Shared/CtaLink";
import * as styles from "./ProductSupportBar.module.scss";

interface IProductSupportBar {
    widgetProps: GatsbyTypes.strapi_ComponentProductSupportBar;
    product: GatsbyTypes.strapi_Product;
    supportTypes: GatsbyTypes.strapi_SupportTypes[] | undefined;
}

export default function ProductSupportBar({ widgetProps, product, supportTypes }: IProductSupportBar): ReactElement {
    const topicLinkArgs = {
        supportType:
            supportTypes?.sort((a, b) => (a.product_types?.length ?? 0) - (b.product_types?.length ?? 0))?.find(x => x.product_types?.some(y => y?.id === product.product_type?.id))?.name ?? "",
        product: encodeURIComponent(removeWhiteSpaces(product?.name) ?? ""),
        downloadId: product.download?.id ?? "",
    };

    function topicLinkIsValid(cta: GatsbyTypes.strapi_ComponentSharedCtaContent | undefined): boolean {
        if (cta?.link?.includes("#activation")) return (product.download?.activation?.length ?? 0) > 0;
        return true;
    }

    return (
        <div className={styles.supportBar}>
            <div className={`mainBox ${styles.inner}`}>
                <div className={styles.title}>
                    <h2>{widgetProps.title}</h2>
                </div>

                {widgetProps.commonTopic && widgetProps.commonTopic.length > 0 && (
                    <div className={styles.links}>
                        <ul>
                            {widgetProps.commonTopic.filter(topicLinkIsValid).map(x => (
                                <li key={x?.id}>
                                    <CtaLink cta={x as GatsbyTypes.strapi_ComponentSharedCtaContent} args={topicLinkArgs} />
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                {widgetProps.question && (
                    <div className={styles.help}>
                        <h3>{widgetProps.question.base?.heading}</h3>
                        <div dangerouslySetInnerHTML={{ __html: widgetProps.question.base?.paragraph ?? "" }}></div>
                        {widgetProps.question.cta && <CtaLink cta={widgetProps.question.cta} />}
                    </div>
                )}
            </div>
        </div>
    );
}

import { ReactElement, useState, useRef } from "react";
import FeatureCard from "./FeatureCard";
import * as styles from "./ProductCommonFeatures.module.scss";

interface IProductCommonFeatures {
    product: GatsbyTypes.strapi_Product;
    showMoreText: string;
    showLessText: string;
}

export default function ProductCommonFeatures({ product, showMoreText, showLessText }: IProductCommonFeatures): ReactElement {
    const [showMore, setShowMore] = useState(false);
    const mainBoxRef = useRef<HTMLDivElement | null>(null);
    const specialFeatures = product.features?.filter(x => x && x.special) ?? [];
    const commonFeatures = product.features?.filter(x => x && !x.special) ?? [];
    const featuresQuantity = specialFeatures.length - 3 + commonFeatures.length;
    let featureCards = [];

    for (let i = 3; i < specialFeatures.length; i++) {
        const feature = specialFeatures[i];
        if (featureCards.length < 4 || showMore) {
            featureCards.push(<FeatureCard key={feature?.id} feature={feature} />);
        }
    }

    for (let i = 0; i < commonFeatures.length; i++) {
        const feature = commonFeatures[i];
        if (featureCards.length < 4 || showMore) {
            featureCards.push(<FeatureCard key={feature?.id} feature={feature} />);
        }
    }

    function handleShowLessClick() {
        setShowMore(false);
        if (mainBoxRef?.current) mainBoxRef.current.scrollIntoView();
    }

    return (
        <div className={styles.productFeatures}>
            <div className={`mainBox ${styles.inner}`} ref={mainBoxRef}>
                {featureCards}
                {!showMore && featuresQuantity > 4 && (
                    <div className={styles.showMoreContainer}>
                        <button type="button" onClick={() => setShowMore(true)}>
                            {showMoreText}
                        </button>
                    </div>
                )}
                {showMore && featuresQuantity > 4 && (
                    <div className={styles.showMoreContainer}>
                        <button type="button" onClick={handleShowLessClick}>
                            {showLessText}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

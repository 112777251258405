import { ReactElement } from "react";
import { ContextCollection } from "../../contexts/DependencyContext";
import ProductHead from "../../components/Widgets/ProductHead";
import ProductDownload from "../../components/Widgets/ProductDownload";
import ProductOverview from "../../components/Widgets/ProductOverview";
import ProductSpecialFeatures from "../../components/Widgets/ProductSpecialFeatures";
import ProductCommonFeatures from "../../components/Widgets/ProductCommonFeatures";
import ProductSupportBar from "../../components/Widgets/ProductSupportBar";
import ProductRelatedProducts from "../../components/Widgets/ProductRelatedProduct";
import ImageGallery from "../../components/Widgets/ImageGallery";
import NoWidget from "../../components/Widgets/NoWidget";
import { IGenericWidget } from "./IGenericWidget";

export type WidgetProps = IGenericWidget & GatsbyTypes.strapi_ProductPageDetailDynamicZone;

export default class ProductWidgetService {
    public getType(widgetProps: WidgetProps): string {
        return (widgetProps.__typename as string)?.replace("strapi_Component", "")?.toLowerCase();
    }

    public getKey(widgetProps: WidgetProps): string {
        return `${this.getType(widgetProps)}-${widgetProps.id}`;
    }

    public getWidget(widgetProps: WidgetProps, product: GatsbyTypes.strapi_Product, supportTypes: GatsbyTypes.strapi_SupportTypes[]): ReactElement {
        const type = this.getType(widgetProps);

        switch (type) {
            case "producthead":
                return <ProductHead widgetProps={widgetProps as GatsbyTypes.strapi_ComponentProductHead} product={product} />;

            case "productdownload":
                return <ProductDownload widgetProps={widgetProps as GatsbyTypes.strapi_ComponentProductDownload} product={product} />;

            case "sharedgenericblock":
                const widget = widgetProps as GatsbyTypes.strapi_ComponentSharedGenericBlock;
                switch (widget.widgetType) {
                    case "commonFeatures":
                        return <ProductCommonFeatures product={product} showMoreText={widget.showMoreText ?? ""} showLessText={widget.showLessText ?? ""} />;
                    case "specialFeatures":
                        return <ProductSpecialFeatures product={product} />;
                }
                break;
            case "productoverview":
                return <ProductOverview widgetProps={widgetProps as GatsbyTypes.strapi_ComponentProductOverview} product={product} />;

            case "productgallery":
                return <ImageGallery allImages={product.images as GatsbyTypes.strapi_UploadFile[]} />;

            case "productsupportbar":
                return <ProductSupportBar widgetProps={widgetProps as GatsbyTypes.strapi_ComponentProductSupportBar} {...{ product, supportTypes }} />;

            case "productrelatedproducts":
                return <ProductRelatedProducts widgetProps={widgetProps as GatsbyTypes.strapi_ComponentProductRelatedProducts} product={product} />;

            default:
                return <NoWidget {...widgetProps} />;
        }
    }
}

ContextCollection.registerTransient(ProductWidgetService);

import { ReactElement } from "react";
import * as styles from "./ProductSpecialFeatures.module.scss";
import { specialFeatureIcon } from "../../Icons";

interface IProductSpecialFeatures {
    product: GatsbyTypes.strapi_Product;
}

export default function ProductSpecialFeatures({ product }: IProductSpecialFeatures): ReactElement {
    const specialFeatures = product.features?.filter(x => x?.special) ?? [];

    return (
        <div className={styles.productSpecialFeatures}>
            <div className={`mainBox ${styles.inner}`}>
                {specialFeatures.slice(0, Math.min(specialFeatures.length, 3)).map(x => (
                    <div key={x?.base?.id} className={styles.specialFeatures}>
                        <h1> {x?.base?.heading} </h1>
                        <div dangerouslySetInnerHTML={{ __html: x?.base?.paragraph ?? "" }}></div>
                        <div className={styles.imageContainer}>{specialFeatureIcon}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}
